import React from 'react';
import {Cell} from '../Cell/Cell';
import {Navigation} from '../../Navigation/Navigation';
import classNames from 'classnames';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';
import s from './ClassicLayout.scss';
import {Alignment, Layout, ProductPageSlotIds} from '../../../constants';
import {LayoutComponentProps} from '../../../types/app-types';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';
import {RatingSummarySlot} from '../../RatingSummarySlot/RatingSummarySlot';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {ProductPageTopSlot} from '../../ProductPageTopSlot/ProductPageTopSlot';

export const ClassicLayout: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const GALLERY_WIDTH = 500;
  const {product} = globals;
  const {addReviewsSlotsToProductPage, renderProductPageSlots} = globals.experiments ?? {};
  const isPluginInstalledInDetails2Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails2];
  const isPluginInstalledInDetails3Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails3];
  const isPluginInstalledInDetails4Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails4];
  const isPluginInstalledInDetails8Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails8];

  return (
    <article className={s.container}>
      <ProductPageTopSlot />
      {settings.shouldShowNavigation && <Navigation className={s.navigation} />}
      <div className={s.row}>
        <section className={s.col}>
          <Cell className={s.gallery}>
            <ProductGalleryLayout
              product={product}
              layoutConfig={createLayoutConfigWithDefaults({
                withMediaBorder: true,
                withImageRatio: true,
                align: Alignment.LEFT,
                dimensions: {
                  mainMedia: {
                    widthConf: {num: GALLERY_WIDTH, unit: 'px'},
                  },
                  thumbnails: {
                    widthConf: {num: GALLERY_WIDTH, unit: 'px'},
                    heightConf: {num: 50, unit: 'px'},
                  },
                },
              })}
              layout={Layout.Classic}
              {...settings}
            />
          </Cell>

          <Cell className={s.description}>
            <ProductDescription description={product.description} />
          </Cell>
          {renderProductPageSlots && (
            <Cell
              className={classNames({
                [s.productPageSlot]: isPluginInstalledInDetails4Slot,
                [s.mediumSpacing]: isPluginInstalledInDetails4Slot,
              })}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails4} />
            </Cell>
          )}
        </section>
        <section className={s.col}>
          <Cell>
            <ProductName name={product.name} />
          </Cell>
          {addReviewsSlotsToProductPage && <RatingSummarySlot />}
          {settings.shouldShowSku && (
            <Cell className={s.sku}>
              <ProductSku />
            </Cell>
          )}
          {renderProductPageSlots && (
            <Cell className={classNames({[s.productPageSlot]: isPluginInstalledInDetails2Slot})}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails2} />
            </Cell>
          )}
          <Cell>
            <DetailsPlaceholder />
          </Cell>
          {settings.shouldShowPrice && (
            <Cell className={s.price}>
              <ProductPrice />
            </Cell>
          )}
          {renderProductPageSlots && (
            <Cell className={classNames({[s.productPageSlot]: isPluginInstalledInDetails3Slot})}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails3} />
            </Cell>
          )}

          {settings.shouldShowDiscountName && product?.itemDiscount?.discountRuleName && (
            <Cell>
              <ProductDiscountName name={product.itemDiscount.discountRuleName} />
            </Cell>
          )}

          {settings.shouldShowProductPaymentBreakdown && (
            <Cell>
              <ProductPaymentBreakdown />
            </Cell>
          )}

          <Cell className={s.options}>
            <ProductOptions
              shouldShowQuantity={settings.shouldShowQuantity}
              shouldShowStockIndicator={settings.shouldShowStockIndicator}
            />
            <ProductPageButtonsContainer />
          </Cell>
          {renderProductPageSlots && (
            <Cell
              className={classNames({
                [s.productPageSlot]: isPluginInstalledInDetails8Slot,
                [s.bigSpacing]: isPluginInstalledInDetails8Slot,
              })}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails8} />
            </Cell>
          )}
          {settings.shouldShowInfoSection && (
            <Cell className={s.info}>
              <InfoSectionLayout />
            </Cell>
          )}
          {renderProductPageSlots && (
            <Cell>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails9} />
            </Cell>
          )}
          {settings.shouldShowSocialNetwork && (
            <Cell className={s.social}>
              <ProductSocial />
            </Cell>
          )}
        </section>
      </div>
      <Cell>
        {addReviewsSlotsToProductPage && <ReviewsSlot />}
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};
