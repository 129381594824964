import React from 'react';

import {Cell} from '../Cell/Cell';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import classNames from 'classnames';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './SpotlightLayout.scss';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';
import {SpotlightLayoutHeader} from './SpotlightLayoutHeader';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {ProductPageSlotIds} from '../../../constants';

export const SpotlightLayout: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const {product} = globals;
  const footerClass = classNames(s.footer, {[s.fullWidth]: settings.isFullWidth});
  const shouldRenderFooter =
    settings.shouldShowInfoSection && product.additionalInfo && product.additionalInfo.length > 0;
  const {addReviewsSlotsToProductPage, renderProductPageSlots} = globals.experiments ?? {};
  const isPluginInstalledInDetails8Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails8];
  const isPluginInstalledInDetails9Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails9];

  return (
    <article>
      <SpotlightLayoutHeader globals={globals} settings={settings} />
      {renderProductPageSlots && (
        <Cell className={classNames({[s.productPageSlotHugeSpacing]: isPluginInstalledInDetails8Slot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails8} />
        </Cell>
      )}
      {shouldRenderFooter && (
        <div className={s.footerContainer}>
          <div className={footerClass}>
            <Cell className={s.info}>
              <InfoSectionLayout />
            </Cell>
          </div>
        </div>
      )}
      {renderProductPageSlots && (
        <Cell className={classNames({[s.productPageSlotBigSpacing]: isPluginInstalledInDetails9Slot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails9} />
        </Cell>
      )}
      <Cell>
        {addReviewsSlotsToProductPage && <ReviewsSlot />}
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};
