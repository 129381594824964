import React from 'react';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductName} from '../../ProductName/ProductName';
import {Cell} from '../Cell/Cell';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {ProductSku} from '../../ProductSku/ProductSku';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './StunningLayout.scss';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';
import {RatingSummarySlot} from '../../RatingSummarySlot/RatingSummarySlot';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {Layout, ProductPageSlotIds} from '../../../constants';
import classNames from 'classnames';

export const StunningLayoutHeader: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const GALLERY_MIN_HEIGHT = 590;
  const {product} = globals;
  const {addReviewsSlotsToProductPage, renderProductPageSlots} = globals.experiments ?? {};
  const isPluginInstalledInDetails2Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails2];
  const isPluginInstalledInDetails3Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails3];
  const isPluginInstalledInMediaSlot = !!globals.slots?.[ProductPageSlotIds.ProductPageMedia1];

  return (
    <header className={s.header}>
      <Cell className={s.gallery}>
        <ProductGalleryLayout
          product={product}
          layoutConfig={createLayoutConfigWithDefaults({
            withDynamicHeight: true,
            dimensions: {
              mainMedia: {
                widthConf: {num: 100, unit: '%'},
                heightConf: {num: GALLERY_MIN_HEIGHT, unit: 'px'},
              },
              thumbnails: {
                widthConf: {num: 100, unit: '%'},
                heightConf: {num: 50, unit: 'px'},
              },
            },
          })}
          layout={Layout.Stunning}
          {...settings}
        />
      </Cell>
      {renderProductPageSlots && (
        <div className={classNames({[s.mediaSlot]: isPluginInstalledInMediaSlot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageMedia1} />
        </div>
      )}
      <Cell className={s.floatSectionWrapper}>
        <section className={s.floatSection}>
          <Cell className={s.title}>
            <ProductName name={product.name} />
          </Cell>
          {addReviewsSlotsToProductPage && <RatingSummarySlot />}
          {settings.shouldShowSku && (
            <Cell className={s.sku}>
              <ProductSku />
            </Cell>
          )}
          {renderProductPageSlots && (
            <Cell className={classNames({[s.productPageSlot]: isPluginInstalledInDetails2Slot})}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails2} />
            </Cell>
          )}
          {settings.shouldShowPrice && (
            <Cell className={s.price}>
              <ProductPrice />
            </Cell>
          )}
          {renderProductPageSlots && (
            <Cell className={classNames({[s.productPageSlot]: isPluginInstalledInDetails3Slot})}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails3} />
            </Cell>
          )}

          {settings.shouldShowDiscountName && product?.itemDiscount?.discountRuleName && (
            <Cell>
              <ProductDiscountName name={product.itemDiscount.discountRuleName} />
            </Cell>
          )}

          {settings.shouldShowProductPaymentBreakdown && (
            <Cell>
              <ProductPaymentBreakdown />
            </Cell>
          )}

          <Cell>
            <DetailsPlaceholder />
          </Cell>
          <Cell className={s.options}>
            <ProductOptions
              shouldShowQuantity={settings.shouldShowQuantity}
              shouldShowStockIndicator={settings.shouldShowStockIndicator}
            />
            <ProductPageButtonsContainer />
          </Cell>
          {settings.shouldShowSocialNetwork && (
            <Cell className={s.social}>
              <ProductSocial />
            </Cell>
          )}
        </section>
      </Cell>
    </header>
  );
};
